@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #13131a!important;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swal2-popup{
  border-width: 1px!important;
  border-style: solid!important;
  border-color: #E0A422!important;
}

.swal2-deny{
  border-width: 1px!important;
  border-style: solid!important;
  border-color: #E0A422!important;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #13131a;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #1a1b1d;
    border-radius: 100vh;
    border: 3px solid #39393C;
  }

  /*.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }*/
}

@layer utilities {
  body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  body::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #13131a;
  }

  body::-webkit-scrollbar-thumb {
    background: #1a1b1d;
    border-radius: 100vh;
    border: 3px solid #39393C;
  }

  /*.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }*/
}



.dark input::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}
