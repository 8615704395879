.fc-header-toolbar {
    margin-bottom: 0!important;
    background-color: #1C1C24;
    padding: 1rem;
    color: #FFF;
}
.fc-toolbar-title{
    font-size: 1em!important;
}

.fc-button-primary{
    background-color: #1C1C24 !important;
    color: #FFF;
    border: none!important;
}

.fc-col-header{
    background-color: #282830;
}


.fc-col-header-cell{
    border: none !important;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.fc .fc-daygrid-day.fc-day-today{
    background-color: #555!important;
}
.fc-day{
    cursor: pointer;
    border-radius: 10px;
}

.fc-day:hover{
    background-color: #555!important;
    color: #FFF;
}

.fc-media-screen{
    border: #282830 solid 1px;
    border-radius: 10px;
}

.fc-daygrid-day-top{
    justify-content: center
}
.fc-event{
    display: flex;
    justify-content: center
}

.fc-highlight{
    background-color: #1C1C24!important;
    border: #E0A422 solid 1px;
    border-radius: 10px;
}

:root {

    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
    --fc-neutral-text-color: #808080;
    --fc-border-color: #1C1C24;

    --fc-button-text-color: #fff;
    --fc-button-bg-color: #2C3E50;
    --fc-button-border-color: #2C3E50;
    --fc-button-hover-bg-color: #1e2b37;
    --fc-button-hover-border-color: #1a252f;
    --fc-button-active-bg-color: #1a252f;
    --fc-button-active-border-color: #151e27;

    --fc-event-bg-color: none;
    --fc-event-border-color: none;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;



    --fc-non-business-color: rgba(215, 215, 215, 0.3);
    --fc-bg-event-color: rgb(143, 223, 130);
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188, 232, 241, 0.3);
    --fc-today-bg-color: rgba(255, 220, 40, 0.15);
    --fc-now-indicator-color: red;
}


/*.fc-day-other{
    background-color: #fcfcfd!important;
}*/



/*.fc-header-toolbar {
    margin-bottom: 0!important;
    background-color: #061f46;
    padding: 1rem;
    color: #e5e7eb;
}

.fc-button-primary{
    background-color: #e5e7eb !important;
    border-color: #e5e7eb !important;
    color: #061f46;
}

.fc-icon{
    color: #061f46;
}

.fc-toolbar-title{
    font-weight: bold;
}

.teste{
    border-width: 5px!important;
    border-color: #FFF!important;
    background-color: #f9fafb;
    color: #475467;
    padding: 6px!important;

}
.fc-col-header-cell{
    border: none !important;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.fc-day-other{
    background-color: #fcfcfd!important;
}

.fc-daygrid-day-top{
    flex-direction: initial!important;
}
.fc .fc-daygrid-day.fc-day-today{
    background-color: #e3ecfb!important;
}
.fc-day{
    cursor: pointer;
}

.days-header{
    font-size: 22px;
}

.fc-daygrid-day-number{
    width: 100%;
}

.fc-h-event{
    background-color: inherit;
    border: none;
}

.fc-toolbar-title::first-letter {
    text-transform: uppercase;
}*/
